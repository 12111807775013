<script lang="ts" setup>
const props = defineProps<{
  description: string
  iconName: string
  iconClass?: string
}>()

// NOTE: 以前のiconify-icon 形式にも対応させるため
const normalizedIconName = computed(() => props.iconName.replace(/:/, '--'))
</script>

<template>
  <VTooltip>
    <span class="tw-iconify" :class="[iconClass, `tw-${normalizedIconName}`]" />
    <template #content>
      <div class="tw-max-w-xs tw-text-sm">
        {{ description }}
      </div>
    </template>
  </VTooltip>
</template>
